import React, { useEffect, useRef } from "react";

// reactstrap components
import {
  Label,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Collapse,
  Button,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ImageGallery from "react-image-gallery";
import api from "./../../config";
import axios from "axios";
import NumberFormat from "react-number-format";
import EnquiryForm from "components/EnquiryForm";
import ShareButtons from "components/ShareButtons";
import OfferForm from "components/OfferForm";

function CarDetails(props) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [itemData, setItemData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [carTitle, setCarTitle] = React.useState("");
  const { id } = props.match.params;
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  React.useEffect(() => {
    axios
      .get(api.API_URL + "/load_car_details?stockno=" + id)
      .then((result) => {
        setItemData(result.data);
        setCarTitle(
          result.data.car.make.name +
            " " +
            result.data.car.model.model +
            " " +
            result.data.car.year
        );
        setLoaded(true);
      })
      .catch(() => {
        props.history.push("/inventory");
      });
    changeCollapse(3);
    document.body.classList.add("landing-page");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);

  let img = [];
  if (loaded) {
    img.push({
      original: api.API_URL + "/storage/" + itemData.car.image,
      thumbnail: api.API_URL + "/storage/" + itemData.car.image,
    });
    if (itemData.images.length > 0)
      itemData.images.map((im) =>
        img.push({
          original: api.API_URL + "/storage/" + im.original,
          thumbnail: api.API_URL + "/storage/" + im.thumbnail,
        })
      );
    document.title = carTitle + " :: " + api.PAGE_TITLE;
  }

  const shareText = "Check out this Car!: " + carTitle;
  const scrollTo = (view) => {
    // changeCollapse(3);
    var x = document.getElementById(view);
    x.scrollIntoView({ behavior: "instant", block: "nearest" });
  };
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div
        className="section profile-content"
        style={{ minHeight: 600, paddingTop: 50, backgroundColor: "#ddd" }}
      >
        <Container
          className="p-2"
          // style={{ backgroundColor: "#fff", borderRadius: 10 }}
        >
          <Card className="p-2">
            {loaded && (
              <CardHeader>
                <h3 className="m-0" style={{ fontWeight: 600 }}>
                  {carTitle}
                </h3>
              </CardHeader>
            )}
            {!loaded ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-3x"></i>
              </div>
            ) : (
              <CardBody id="accordion">
                <div>
                  {/* <h2 className="text-bold">Mecerdes Benz{itemData.title}</h2> */}
                </div>
                <Row>
                  <div className="image col-lg-7">
                    <div className="">
                      {itemData && itemData.images && (
                        <ImageGallery items={img} />
                      )}
                    </div>
                  </div>

                  <div className="content col-lg-4">
                    <div className="">
                      {/* <h3 style={{ fontWeight: 600 }}>
                        Vehicle Spceifications
                      </h3> */}
                      <Badge className="badge-info">
                        <strong style={{ fontWeight: 600, fontSize: 22 }}>
                          <NumberFormat
                            value={itemData.car.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={itemData.car.currency === "NGN" ? "₦" : "$"}
                          />
                        </strong>
                      </Badge>
                      <hr />
                      <div className="pl-2">
                        {/* <div className=" col-lg-5"> */}
                        <Row>
                          <Label className="optiontitle1">Color</Label>
                          <Label className="optioninfo">
                            {itemData.car.color}
                          </Label>
                        </Row>

                        <Row>
                          <Label className="optiontitle1">Millage</Label>
                          <Label className="optioninfo">
                            {itemData.car.millage}
                          </Label>
                        </Row>
                        <Row row className="row">
                          <Label className="optiontitle1">Year</Label>
                          <Label className="optioninfo">
                            {itemData.car.year}
                          </Label>
                        </Row>
                        <div className="row">
                          <Label className="optiontitle1">VIN</Label>
                          <Label className="optioninfo">
                            {itemData.car.vin}
                          </Label>
                        </div>
                        {/* </div>
                        <div className="col-lg-5"> */}
                        <div className="row">
                          <Label className="optiontitle1">Drive</Label>
                          <Label className="optioninfo">
                            {itemData.car.drive}
                          </Label>
                        </div>
                        <div className="row">
                          <Label className="optiontitle1">Transmission</Label>
                          <Label className="optioninfo">
                            {itemData.car.transmission}
                          </Label>
                        </div>
                        <div className="row">
                          <Label className="optiontitle1">Fuel</Label>
                          <Label className="optioninfo"></Label>
                        </div>

                        <div className="row">
                          <Label className="optiontitle1">
                            Engine Capacity
                          </Label>
                          <Label className="optioninfo">
                            {itemData.car.engine_type}
                          </Label>
                        </div>
                        {/* </div> */}
                        <div className="row mt-2 mr-2 float-left">
                          <Button
                            onClick={() => scrollTo("MakeOffer")}
                            className="btn btn-default text-white mr-3"
                          >
                            Make Offer
                          </Button>
                          <Button
                            onClick={() => scrollTo("Enquiry")}
                            className="btn btn-default btn-sm text-white"
                          >
                            Inquire
                          </Button>
                          {/* <a className="btn btn-success text-white mr-2">Send to a friend</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <CardHeader
                  className="card-collapse mt-5"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#aaa",
                    color: "#fff",
                  }}
                  id="headingOne"
                  role="tab"
                  onClick={(e) => {
                    e.preventDefault();
                    changeCollapse(1);
                  }}
                >
                  <h5 className="mb-0 panel-title">
                    <a
                      aria-expanded={collapses.includes(1)}
                      className="collapsed"
                      data-parent="#accordion"
                      href="#pablo"
                      id="collapseOne"
                      style={{ fontWeight: "bold", color: "#fff" }}
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(1);
                      }}
                    >
                      Vehicle Details {"   "}
                    </a>
                    <i className="nc-icon nc-minimal-down float-right" />
                  </h5>
                </CardHeader>
                <Collapse isOpen={collapses.includes(1)}>
                  <CardBody>
                    <div
                      style={{ fontWeight: 500 }}
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: itemData.car.description,
                      }}
                    ></div>
                  </CardBody>
                </Collapse>
                <CardHeader
                  className="card-collapse"
                  id="headingTwo"
                  role="tab"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#aaa",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    changeCollapse(2);
                  }}
                >
                  <h5 className="mb-0 panel-title">
                    <a
                      aria-controls="collapseTwo"
                      aria-expanded={collapses.includes(2)}
                      className="collapsed"
                      data-parent="#accordion"
                      href="#pablo"
                      id="collapseTwo"
                      style={{ fontWeight: "bold", color: "#fff" }}
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(2);
                      }}
                    >
                      Vehicle Options
                    </a>
                    <i className="nc-icon nc-minimal-down float-right" />
                  </h5>
                </CardHeader>
                <Collapse isOpen={collapses.includes(2)}>
                  <CardBody>
                    <div className="column-wrap">
                      {itemData.car.options.map((item) => (
                        <li>
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              textAlign: "justify",
                            }}
                          >
                            {item.option}
                          </span>
                        </li>
                      ))}
                    </div>
                  </CardBody>
                </Collapse>
                <CardHeader
                  className="card-collapse"
                  id="headingThree"
                  role="tab"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#aaa",
                    color: "#fff",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    changeCollapse(3);
                  }}
                >
                  <h5 className="mb-0 panel-title">
                    <a
                      aria-controls="collapseThree"
                      aria-expanded={collapses.includes(3)}
                      className="collapsed"
                      data-parent="#accordion"
                      href="#pablo"
                      id="collapseThree"
                      style={{ fontWeight: "bold", color: "#fff" }}
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(3);
                      }}
                    >
                      Inquiry{" "}
                    </a>
                    <i className="nc-icon nc-minimal-down float-right" />
                  </h5>
                </CardHeader>
                <Collapse isOpen={collapses.includes(3)}>
                  <CardBody className="row">
                    {/* <ShareButtons
                        shareUrl={window.location.href}
                        title={carTitle}
                        shareText={shareText}
                      /> */}

                    <div className="col-lg-6" id="Enquiry">
                      <EnquiryForm
                        link={window.location.href}
                        carTitle={carTitle}
                      />
                    </div>
                    <div className="col-lg-6" id="MakeOffer">
                      <OfferForm
                        link={window.location.href}
                        carTitle={carTitle}
                        currency={itemData.car.currency}
                      />
                    </div>
                  </CardBody>
                </Collapse>
              </CardBody>
            )}
          </Card>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default CarDetails;
