/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, withRouter } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { isPropertySignature } from "typescript";

function ExamplesNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 100 ||
        document.body.scrollTop > 100
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 101 ||
        document.body.scrollTop < 101
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand data-placement="bottom" to="/" tag={Link}>
            <img
              src={require("assets/img/logo.jpeg")}
              style={{ width: 80 }}
              alt=""
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/financing" tag={Link}>
                {/* <i className="nc-icon nc-layout-11" /> */}
                Financing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/inventory" tag={Link}>
                {/* <i className="nc-icon nc-book-bookmark" /> */}
                Inventory
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/pre-order"
                tag={Link}
                data-placement="bottom"
                title="Pre order your car"
              >
                {/* <i className="fa fa-twitter" /> */}
                {/* <p className="d-lg-none">Our Sevices</p> */}
                <p>Pre-Order</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/about"
                tag={Link}
                data-placement="bottom"
                title="Like us on Facebook"
              >
                {/* <i className="fa fa-facebook-square" /> */}
                About Us
                {/* <p className="d-lg-none">About Us</p> */}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/contact"
                tag={Link}
                data-placement="bottom"
                title="Contact Us"
              >
                {/* <i className="fa fa-instagram" /> */}
                Contact Us
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                color="default"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                nav
                onClick={(e) => e.preventDefault()}
                role="button"
              >
                services
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="dropdownMenuButton"
                className="dropdown-primary"
              >
                <DropdownItem href="#" onClick={(e) => e.preventDefault()}>
                  Trade-In Your Vehicle
                </DropdownItem>
                <DropdownItem
                  href="pre-order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/pre-order");
                  }}
                >
                  Pre-Order
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default withRouter(ExamplesNavbar);
