/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components

function ProfilePageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg/landing_bg.jpg") + ")",
        }}
        className="page-header page-header-xs"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        {/* <h3>Find your used car in US</h3> */}

        {/* <div className="m-3"></div>
        <div className="searchbar">
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Car Make or Model"
              />
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option>Price</option>
                <option>$500 - $999</option>
                <option>$1000 - $1099</option>
                <option>$2000 - $2499</option>
                <option>$2500 - $2999</option>
                <option>$3000 - $3499</option>
                <option>$3500 - $3999</option>
                <option>About $4000</option>
              </select>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option>City</option>
                <option>New York</option>
                <option>San Joes</option>
                <option>Atlanta</option>
                <option>Kennesaw</option>
                <option>Merry Land</option>
                <option>Star City</option>
              </select>
            </div>
            <div className="col-md-2">
              <input type="submit" className="btn" value="Search" />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ProfilePageHeader;
