/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      className="footer footer-black footer-white"
      style={{ backgroundColor: "#061C33" }}
    >
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/about">Privacy Policy</Link>
              </li>
              <li>
                <a href="https://instagram.com" target="_blank">
                  <i className="fa fa-instagram fa-2x" />
                </a>
              </li>
              <li>
                <a href="https://facebook.com" target="_blank">
                  <i className="fa fa-facebook fa-2x" />
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              IGI Autos Inc. © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" />
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
