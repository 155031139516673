/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { Link } from "react-router-dom";
import title from "../../config";

function PreOrder() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.title = title.PAGE_TITLE + " :: Pre-Order Your Vehicle";
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div
        className="section  profile-content"
        style={{ backgroundColor: "#eee", position: "relative" }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <div className="section-content">
                <h2 className="title">Pre Order Your Vehicle</h2>
                <p className="text-content">
                  We are 100% Auto Dealers with Showrooms in Georgia, USA &
                  Lagos Nigeria. We have been into Auto business for a while and
                  as part of our effort to make clean cars affordable to
                  Nigerians and due to popular demands, We have decided to
                  officially launch our Pre Order Process to our teaming
                  customers and prospective clients.
                </p>
                <p className="text-content">
                  {" "}
                  Tell us your specifications, We search for your car, forward
                  you 10 - 20 photos with full details & VIN of the Car, You pay
                  40% of total cost, a Legal Agreement is tied. Your car arrives
                  in 31 days maximum and you pay balance after physical
                  inspection. You are assured of saving a minimum of 300,000
                  Naira compared to purchases made from other dealers. We are
                  Auto Vendors with most Nigerian banks and 2 Leasing firms in
                  Lagos, Nigeria.
                </p>
                <p className="text-content">
                  To get started, first{" "}
                  <Link to="/inventory">
                    <b>BROWSE THE INVENTORY</b>{" "}
                  </Link>{" "}
                  then select any car you want, then click on <b>Pre Order</b>{" "}
                  to fill the form, and we will tak it from there.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default PreOrder;
