import React from "react";
import { Card } from "reactstrap";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  TwitterIcon,
} from "react-share";
// core components

export default function ShareButtons(props) {
  const { shareUrl, shareText, title } = props;

  return (
    <Card className="row float-right card-sm">
      <div className="card-header py-1" style={{ fontWeight: 400 }}>
        Share with friends
      </div>

      <div className="row card-body py-1">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button mx-2"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={shareText}
          separator=" :: "
          className="Demo__some-network__share-button mx-2"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <EmailShareButton
          url={shareUrl}
          subject="Check out this vehicle"
          body={"Thought you might be interested in this " + title}
          className="Demo__some-network__share-button mx-2"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
        <TwitterShareButton
          url={shareUrl}
          hashtage="#IGIAutos"
          related="IGIAutos"
          className="Demo__some-network__share-button mx-2"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>

      {/* <Facebook url={url} />
            <Twitter url={url} shareText={shareText} />
            <WhatsApp url={url} message={shareText} />
            <Telegram url={url} message={shareText} />
            <Email url={url} subject={shareText} /> */}
    </Card>
  );
}
