const GlobalVar = Object.freeze({
  API_URL: "https://api.igiautos.com",
  PAGE_TITLE: "IGI Autos :: Buy Used and New Cars & Trucks ",

  // API_URL: "http://localhost/igiautos/api/public",
  // API_URL: "http://127.0.0.1:5000",

  // ... more of your variables
});

export default GlobalVar;
