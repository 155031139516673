/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import title from "../../config";
import { Link } from "react-router-dom";

function About() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.title = title.PAGE_TITLE + " :: About Us";
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div
        className="section  profile-content"
        style={{ backgroundColor: "#eee", position: "relative" }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <div className="section-content">
                <h2 className="title">Who we are..</h2>
                <p className="text-content">
                  Thank you for visiting our website and considering us for the
                  purchase of your next vehicle!
                </p>
                <p className="text-content">
                  IGI Autos Nigeria offers high quality vehicles at very
                  competitive prices! Our goal is to provide our customers in
                  Nigeria and all over with the best possible value as well as a
                  great overall buying experience.
                </p>
                <p className="text-content">
                  We have been consistent over the last 5 Years. An initiative
                  that has made several hundreds of our customers in Nigeria
                  proud owners of clean vehicles at affordable prices.
                </p>
                <p className="text-content">
                  We specialize in hand picked vehicles that we get from the
                  largest auctions in the USA and Canada.
                </p>
                <p className="text-content">
                  All our vehicles have a clean carfax/Autocheck history report,
                  no accident, no salvaged title and they also go through an
                  extensive mechanical check up and then become part of our
                  certified{" "}
                  <Link
                    style={{ color: "inherit", fontWeight: "600" }}
                    to="/inventory"
                  >
                    inventory
                  </Link>
                  . We care for our cars and the outstanding quality and
                  condition of our vehicles show it…
                </p>
                <p className="text-content">
                  You will find the purchase experience here to be honest and
                  straight forward. With the passion for Clean Cars & products
                  that my sales staff and I possess, I am confident that we will
                  find you the right vehicle to suit your needs, whether the
                  need is for affordability, style, performance, or safety.
                </p>
                <p className="text-content">
                  Our inventory is extensive with new vehicles coming in every
                  day. All our vehicles are carefully inspected before purchase
                  and shipping. Avoid paying high prices – check us out for
                  unbeatable prices and personal service!!!
                </p>
                <p className="text-content">
                  Warranties are available for every car.
                </p>
                <p className="text-content">
                  <br />
                </p>
                <p className="text-content">
                  <b>Ikponmwosa Pero ( IGI AUTOS )</b> <br />
                  <i>Managing Director</i>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default About;
