import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Iframe from "react-iframe";
import api from "../../config";
import Axios from "axios";

function Contact(props) {
  const [activeTab, setActiveTab] = React.useState("1");

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.title = api.PAGE_TITLE + " :: Contact Us";
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [inputValues, setInputValues] = React.useState({
    name: "",
    email: "",
    phone: "",
    City: "",
    State: "",
    Message: "",
    Country: "",
    msgType: "contact",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({ ...inputValues, [id]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios.post(api.API_URL + "/send_message", inputValues)
      .then(() => {
        setLoading(false);
        setSent(true);
        document.getElementById("contact-form").reset();
      })
      .catch((error) => {});
  };
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />

      <div
        className="section profile-content"
        style={{
          backgroundColor: "#eee",
          position: "relative",
          fontWeight: 500,
        }}
      >
        {/* <Container>
          <div
            className="section landing-section"
            // style={{ backgroundColor: "#002C63", color: "#ddd" }}
          > */}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <div className="section-content">
                <h2 className="text-center">Get in touch with us..</h2>

                <Row>
                  <Col md="5" className="mt-0" style={{ paddingLeft: "-50px" }}>
                    <div className="card-body">
                      <div className="info info-horizontal row py-0">
                        <div className="icon icon-info col-2">
                          <i
                            className="nc-icon nc-pin-3"
                            style={{ fontSize: 23 }}
                          ></i>
                        </div>
                        <div className="description col-10 mt-4">
                          <h5 className="info-title">Find us at the office</h5>
                          <p>
                            <Label style={styles.txt2}>
                              2182 Coffee Rd ste D
                            </Label>
                            <Label style={styles.txt2}>
                              Lithonia, GA 30058, USA
                            </Label>
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal row py-0">
                        <div className="icon icon-info col-2">
                          <i
                            className="nc-icon nc-badge"
                            style={{ fontSize: 23 }}
                          ></i>
                        </div>
                        <div className="description col-10 mt-4">
                          <h5 className="info-title">Give us a ring</h5>
                          <p>
                            <Label style={styles.txt1}>Office:</Label>
                            <Label style={styles.txt2}> (770) 559-0718</Label>
                            <br />
                            <Label style={styles.txt1}> Cell: </Label>
                            <Label style={styles.txt2}>470-332-0534</Label>
                            <br />
                            <span style={{ fontWeight: 500 }}>
                              Mon - Fri, 8:00-18:00
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="info info-horizontal row py-0">
                        <div className="icon icon-info col-2">
                          <i
                            className="nc-icon nc-email-85"
                            style={{ fontSize: 23 }}
                          ></i>
                        </div>
                        <div className="description col-10 mt-4">
                          <h5 className="info-title">Send an email</h5>
                          <p>
                            <Label style={styles.txt2}>info@igiautos.com</Label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <Form
                      className="contact-form"
                      id="contact-form"
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        {sent && (
                          <div
                            className="alert alert-info text-center"
                            style={{ fontWeight: 400 }}
                          >
                            Thank you for your interest in this vehicle. We have
                            received your message. We will get back to you as
                            soon as possible
                          </div>
                        )}
                        <Col md="6">
                          <label>Name</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Name"
                              type="text"
                              id="name"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <label>Phone</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-tag-content" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Phone number"
                              type="text"
                              required
                              id="phone"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label>Email</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email address"
                              type="email"
                              required
                              id="email"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <label>City</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-tag-content" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="City"
                              type="text"
                              id="City"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label>State</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-tag-content" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Enter your state"
                              type="text"
                              id="State"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <label>Country</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-tag-content" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Country"
                              type="text"
                              id="Country"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <label>Message</label>
                      <Input
                        placeholder="Enquiry"
                        type="textarea"
                        rows="4"
                        id="Message"
                        onChange={handleChange}
                      />
                      <Row>
                        <Col className="ml-auto mr-auto" md="6">
                          <Button
                            type="submit"
                            className="btn-fill"
                            color="danger"
                            disabled={loading}
                            size="lg"
                          >
                            {loading ? "Sending.." : "Send Message"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        {/* </div>
        </Container> */}
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d106171.21076633333!2d-84.1866768!3d33.738686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5ade423804b17%3A0x14e2c51132b197e0!2sIGI%20AUTOS!5e0!3m2!1sen!2sng!4v1600269375020!5m2!1sen!2sng"
          width="100%"
          height="450px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />
      </div>

      <DemoFooter />
    </>
  );
}

export default Contact;

const styles = {
  txt1: { width: 60, fontWeight: 400 },
  txt2: { fontWeight: "bold" },
};
