import Axios from "axios";
import React from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Card,
  Form,
} from "reactstrap";

import api from "../config";

function EnquiryForm(props) {
  var iniMsg =
    "I am interested in this vehicle (" +
    props.carTitle +
    "). I will like to get more information about the payment and shipment.";
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [inputValues, setInputValues] = React.useState({
    fullname: "",
    email: "",
    phone: "",
    location: "",
    message: iniMsg,
    carLink: props.link,
    carTitle: props.carTitle,
    msgType: "enquiry",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({ ...inputValues, [id]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios.post(api.API_URL + "/send_message", inputValues)
      .then(() => {
        setLoading(false);
        setSent(true);
        document.getElementById("car-form").reset();
        if (props.close)
          setTimeout(() => {
            props.close();
          }, 3000);
      })
      .catch((error) => {});
  };
  return (
    <Card>
      <CardHeader>
        <h5 style={{ fontWeight: "bold", textAlign: "center" }}>
          Make Enquiry for this vehicle
        </h5>
        {sent && (
          <div
            className="alert alert-info text-center"
            style={{ fontWeight: 400 }}
          >
            Thank you for your interest in this vehicle. We have received your
            message. We will get back to you as soon as possible
          </div>
        )}
      </CardHeader>
      <Form onSubmit={handleSubmit} id="car-form">
        <CardBody>
          <FormGroup>
            <Input
              placeholder="*Full Name"
              id="fullname"
              type="text"
              required
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="*Email"
              id="email"
              type="email"
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Phone number"
              type="tel"
              onChange={handleChange}
              id="phone"
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="*Location"
              id="location"
              onChange={handleChange}
              type="text"
              required
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Enquiry"
              type="textarea"
              rows="4"
              id="message"
              required
              onChange={handleChange}
              defaultValue={iniMsg}
            />
          </FormGroup>
          <FormGroup>
            <div className="text-center">
              <Button
                type="submit"
                className="btn-fill"
                color="danger"
                disabled={loading}
                size="lg"
              >
                {loading ? "Sending.." : "Send"}
              </Button>
            </div>
          </FormGroup>
        </CardBody>
      </Form>
    </Card>
  );
}

export default EnquiryForm;
