import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { Link } from "react-router-dom";

function PreOrderVehicle() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />

      <div
        className="section "
        style={{ backgroundColor: "#ccc", position: "relative" }}
      >
        <Container className="section-content">
          <h2 className="title">Pre Order Your Vehicle</h2>
          <Row>
            <p className="text-content">
              We are 100% Auto Dealers with Showrooms in Georgia, USA & Lagos
              Nigeria. We have been into Auto business for a while and as part
              of our effort to make clean cars affordable to Nigerians and due
              to popular demands, We have decided to officially launch our Pre
              Order Process to our teaming customers and prospective clients.
            </p>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Form className="contact-form">
                <Row>
                  <Col md="6">
                    <label>Firstname</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Firstname" type="text" />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label>Lastname</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Lastname" type="text" />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <label>Email</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email address" type="email" />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label>Phone</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Phone number" type="tel" />
                    </InputGroup>
                  </Col>
                </Row>
                <label>Message</label>
                <Input placeholder="Enquiry" type="textarea" rows="4" />
                <Row>
                  <Col className="ml-auto mr-auto" md="4">
                    <Button className="btn-fill" color="danger" size="lg">
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default PreOrderVehicle;
