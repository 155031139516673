import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Badge,
  Modal,
  Card,
} from "reactstrap";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  TwitterIcon,
} from "react-share";
// core components

import { Link } from "react-router-dom";
import api from "../config";
import NumberFormat from "react-number-format";
import EnquiryForm from "./EnquiryForm";
import ShareButtons from "./ShareButtons";

function ListCard(props) {
  const { car } = props;
  const [activeTab, setActiveTab] = React.useState("1");
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //   document.documentElement.classList.remove("nav-open");
  //   React.useEffect(() => {
  //     document.body.classList.add("landing-page");
  //     return function cleanup() {
  //       document.body.classList.remove("landing-page");
  //     };
  //   });
  var title = car.model.makes.name + " " + car.model.model + " " + car.year;
  var carLink =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/vehicle/" +
    car.stock_no +
    "/" +
    car.slug;
  const shareUrl = carLink;
  const shareText = "Check out this Car!: " + title;
  return (
    <>
      <Row style={styles.card}>
        <div className="image col-lg-4" style={styles.image}>
          <div style={styles.imgContainer}>
            <Link to={`/vehicle/${car.stock_no}/${car.slug}`}>
              <img
                src={api.API_URL + "/storage/" + car.image}
                style={styles.img}
              />
            </Link>
          </div>
        </div>
        <div className="content col-lg-8">
          <div>
            <h5
              className="text-secondary"
              style={{ float: "left", marginRight: 50, fontWeight: "bold" }}
            >
              {car.year} {car.model.makes.name} {car.model.model}
            </h5>
            <Badge className="badge-danger">
              <strong style={{ fontWeight: 600, fontSize: 22 }}>
                <NumberFormat
                  value={car.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={car.currency === "NGN" ? "₦" : "$"}
                />
              </strong>
            </Badge>
          </div>
          <div className="clearfix"></div>
          {/* <div className="filter" /> */}

          <div className="row mt-3 col-12">
            <div className=" col-lg-6">
              <div>
                <Label className="optiontitle">Color:</Label>
                <Label className="optioninfo">{car.color}</Label>
              </div>

              <div>
                <Label className="optiontitle">Millage:</Label>
                <Label className="optioninfo">{car.millage}</Label>
              </div>
              <div>
                <Label className="optiontitle">Year:</Label>
                <Label className="optioninfo">{car.year}</Label>
              </div>
              <div>
                <Label className="optiontitle">VIN:</Label>
                <Label className="optioninfo">{car.vin}</Label>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <Label className="optiontitle2">Drive:</Label>
                <Label className="optioninfo">{car.drive}</Label>
              </div>
              <div>
                <Label className="optiontitle2">Transmission:</Label>
                <Label className="optioninfo">{car.transmission}</Label>
              </div>
              <div>
                <Label className="optiontitle2">Fuel:</Label>
                <Label className="optioninfo"></Label>
              </div>

              <div>
                <Label className="optiontitle2">Engine Capacity:</Label>
                <Label className="optioninfo">{car.engine_type} </Label>
              </div>
            </div>
          </div>
          <div className="row mt-2 mr-2 float-left">
            <Link
              className="btn btn-default text-white mr-3"
              to={`/vehicle/${car.stock_no}/${car.slug}`}
            >
              View Details
            </Link>
            <Button
              className="btn btn-default btn-sm text-white"
              onClick={toggleModal}
            >
              Inquire about this vehicle
            </Button>
            {/* <a className="btn btn-success text-white mr-2">Send to a friend</a> */}
          </div>
          <ShareButtons
            shareUrl={shareUrl}
            title={title}
            shareText={shareText}
          />
        </div>
      </Row>
      {/* Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-center" id="exampleModalLabel">
            Enquiry Form
          </h5>
        </div>
        <div className="modal-body">
          <EnquiryForm link={carLink} carTitle={title} close={toggleModal} />
        </div>
      </Modal>
    </>
  );
}

export default ListCard;

const styles = {
  img: { height: 200, width: 300, resizeMode: "center", borderRadius: 10 },
  imgContainer: {
    position: "relative",
    textAlign: "center",
    color: "white",
  },
  Imgbottom: {
    position: "absolute",
    bottom: -30,
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(11, 16, 17,0.7)",
    width: "83.5%",
    height: 60,
    fontWeight: "bold",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    color: "white",
  },
  card: {
    padding: 20,
    border: "#ccc thick solid",
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: "#fff",
  },
  image1: {
    position: "relative",
    float: "left",
    maxWidth: 257,
    margin: "0 28px 0 0",
    paddingTop: 8,
    overflow: "hidden",
  },
};
