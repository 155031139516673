import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//import liraries
import React, { Component, useState } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import api from "./../config";
import axios from "axios";
import NumberFormat from "react-number-format";

// create a component
const Slider = (props) => {
  const [images, setImages] = useState([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  // setImages(result.data)
  React.useEffect(() => {
    axios
      .get(api.API_URL + "/get_featured")
      .then((result) => setImages(result.data));
  }, []);

  return (
    <Container>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={8000}
        keyBoardControl={true}
        // customTransition="all .5"
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-1-px"
      >
        {images.map((item, index) => (
          <div style={styles.imgContainer} key={index}>
            <Link to={"/vehicle/" + item.stock_no + "/" + item.slug} tag={Link}>
              <img
                src={api.API_URL + "/storage/" + item.image}
                style={styles.img}
              />
              <div style={styles.Imgbottom}>
                <h5 style={{ fontWeight: "600" }}>
                  {item.year} {item.model.makes.name} {item.model.model}
                </h5>
                <NumberFormat
                  value={item.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={item.currency === "NGN" ? "₦" : "$"}
                />
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

//make this component available to the app
export default Slider;

const styles = {
  img: { height: 200, width: 300, borderRadius: 10, resizeMode: "center" },
  imgContainer: {
    position: "relative",
    textAlign: "center",
    color: "white",
  },
  Imgbottom: {
    position: "absolute",
    bottom: -30,
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(11, 16, 17,0.7)",
    width: "83.5%",
    height: 60,
    fontWeight: "bold",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    color: "white",
  },
};
