import React from "react";
import { withRouter } from "react-router";

// reactstrap components
import {
  Container,
  Card,
  CardHeader,
  CardFooter,
  Col,
  Row,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Img1 from "../../assets/img/bg/landing_bg.jpg";
import Img2 from "../../assets/img/bg/bbg.jpg";

class BlinkingComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      BlinkText: false,
    };
    setInterval(
      () => {
        this.setState((previousState) => {
          return { BlinkText: !previousState.BlinkText };
        });
      },
      // Define any blinking time.
      2000
    );
  }

  render = () => (
    <Button
      color={(this.state.BlinkText ? "neutral" : "danger") + " float "}
      outline={this.state.BlinkText}
      style={{ padding: "15px", marginTop: 100 }}
      onClick={this.props.handleclick}
    >
      PRE ORDER YOUR CAR FROM THE USA TO LAGOS, 40% DEPOSIT, 31 DAYS DELIVERY
      !!!
    </Button>
  );
}

function LandingPageHeader(props) {
  let pageHeader = React.createRef();
  const pictureArray = [Img1, Img2];
  const randomIndex = Math.floor(Math.random() * pictureArray.length);
  const selectedPicture = pictureArray[randomIndex];
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current)
          pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + selectedPicture + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          {/* <Carousel activeIndex={activeIndex} next={next} previous={previous}>
           
            {items.map((item) => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <img src={item.src} alt={item.altText} />
                  <CarouselCaption
                    captionText={item.caption}
                    captionHeader=""
                  />
                </CarouselItem>
              );
            })}
            
          </Carousel> */}

          <div className="motto text-center">
            {/* <h1>Welcome to IGI Autos</h1>
            <h3>Start designing your landing page here.</h3> */}
            <br />
            <Row
              className="d-lg-none d-flex justify-content-center text-center"
              style={{ height: 200 }}
            >
              {/* <img
                src={require("assets/img/logo.png")}
                style={{ width: 150 }}
              /> */}
            </Row>
            <Row>
              <Col
                lg="6"
                xl="4"
                className=" d-flex justify-content-center text-center"
              >
                <Link to="/inventory" tag={Link}>
                  <Card style={styles.cardLink}>
                    <CardHeader style={styles.cardHead} className="py-4">
                      <img
                        src={require("assets/img/car-icon.png")}
                        width={100}
                        alt=""
                      />
                    </CardHeader>

                    <CardFooter>
                      <h4 style={{ ...styles.cardTxt, fontSize: 21 }}>
                        View Inventory
                      </h4>
                      <span>We Offer finest preorder vehicles</span>
                    </CardFooter>
                  </Card>
                </Link>
              </Col>
              <Col
                lg="6"
                xl="4"
                className=" d-flex justify-content-center text-center"
              >
                <Link to="/financing" tag={Link}>
                  <Card style={styles.cardLink}>
                    <CardHeader style={styles.cardHead}>
                      <i
                        style={{ width: 60 }}
                        className="fa fa-tasks fa-5x"
                        aria-hidden="true"
                      ></i>
                    </CardHeader>

                    <CardFooter>
                      <h4 style={styles.cardTxt}>Apply Online</h4>
                      <span>Start our simple approval process</span>
                    </CardFooter>
                  </Card>
                </Link>
              </Col>
              <Col
                lg="6"
                xl="4"
                className="d-flex justify-content-center text-center"
              >
                <Link to="/contact">
                  <Card style={{ ...styles.cardLink, minWidth: 200 }}>
                    <CardHeader style={styles.cardHead}>
                      <i
                        style={{ width: 50 }}
                        className="fa fa-map-marker fa-5x"
                        aria-hidden="true"
                      ></i>
                    </CardHeader>
                    <CardFooter>
                      <h4 style={styles.cardTxt}>Contact Us</h4>
                      <span>Get in touch with us with ease </span>
                    </CardFooter>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row
              className="d-lg-none d-flex justify-content-center text-center"
              style={{ height: 200 }}
            ></Row>

            <Row
              className=" justify-content-center text-center"
              style={{ position: "relative" }}
            >
              <div style={styles.centeredElement}>
                <BlinkingComponent
                  handleclick={() => props.history.push("/pre-order")}
                />
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withRouter(LandingPageHeader);

const styles = {
  cardLink: {
    width: "90%",
    backgroundColor: "rgb(0, 44, 99, 0.8)",
    border: "2px solid white",
    // cursor: "pointer",
    color: "white",
    borderRadius: 5,
  },
  cardHead: {
    backgroundColor: "rgb(0, 44, 99, 1)",
    margin: 2,
  },
  cardTxt: { fontWeight: "bold", textTransform: "uppercase" },
  centeredElement: {
    // height: 100,
    // width: 400,
    position: "absolute",
    left: "50%",
    marginLeft: -20,
    top: "50%",
    marginTop: 50,
    transform: "translate(-50%,-50%)",
  },
};
