import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ListCard from "components/ListCard";
import PaginationList from "react-pagination-list";
import api from "./../../config";
import axios from "axios";

function Listing() {
  const [activeTab, setActiveTab] = React.useState("1");
  const [itemData, setItemData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  React.useEffect(() => {
    axios.get(api.API_URL + "/get_cars").then((result) => {
      setItemData(result.data);
      setLoaded(true);
    });
    document.title = api.PAGE_TITLE + " :: Vehicle inventory";
    document.body.classList.add("landing-page");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div
        className="section profile-content pt-1"
        style={{ backgroundColor: "#ddd" }}
      >
        <Container>
          <h2 className="text-center text-bold text-black">Our Inventory</h2>
          {!loaded && (
            <div
              className="text-center pt-5"
              style={{
                height: 250,
                border: "solid thin gray",
                marginTop: 30,
                borderRadius: 20,
              }}
            >
              <h3>Loading... Please wait</h3>
              <i className="fa fa-spinner fa-spin fa-4x"></i>
            </div>
          )}
          <div style={{ minHeight: 250 }}>
            <PaginationList
              data={itemData}
              pageSize={10}
              renderItem={(item, key) => <ListCard car={item} key={key} />}
            />
          </div>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default Listing;
