import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import "index.css";
import "react-quill/dist/quill.snow.css"; // ES6
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import About from "views/examples/About.js";
import RegisterPage from "views/examples/RegisterPage.js";
import Listing from "views/examples/Listing.jsx";
import CarDetails from "views/examples/CarDetails";
import PreOrder from "views/examples/PreOrder";
import Financing from "views/examples/Financing";
import Contact from "views/examples/Contact";
import PreOrderVehicle from "views/examples/PreOrderVehicle";
import ScrollToTop from "components/ScrollToTop";
// others

ReactDOM.render(
  <Fragment>
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
        <Route path="/financing" render={(props) => <Financing {...props} />} />
        <Route
          path="/pre-order"
          exact
          render={(props) => <PreOrder {...props} />}
        />
        <Route
          path="/pre-order/:stockid"
          render={(props) => <PreOrderVehicle {...props} />}
        />
        <Route path="/about" render={(props) => <About {...props} />} />
        <Route path="/inventory" render={(props) => <Listing {...props} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        <Route
          path="/vehicle/:id/:slug"
          render={(props) => <CarDetails {...props} />}
        />
        <Route
          path="/register-page"
          render={(props) => <RegisterPage {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </Fragment>,
  document.getElementById("root")
);
