import Axios from "axios";
import React from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Card,
  Form,
  Col,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import api from "../config";

function OfferForm(props) {
  var iniMsg =
    "I am interested in this vehicle (" +
    props.carTitle +
    "). I will like to get more information about the payment and shipment.";
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [inputValues, setInputValues] = React.useState({
    fullname: "",
    email: "",
    phone: "",
    offer: "",
    comment: "",
    carLink: props.link,
    carTitle: props.carTitle,
    msgType: "offer",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({ ...inputValues, [id]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios.post(api.API_URL + "/send_message", inputValues)
      .then(() => {
        setLoading(false);
        setSent(true);
        document.getElementById("car-form").reset();
        if (props.close)
          setTimeout(() => {
            props.close();
          }, 3000);
      })
      .catch((error) => {});
  };
  return (
    <Card>
      <CardHeader style={{ backgroundColor: "#243655" }}>
        <h5 style={{ fontWeight: "bold", textAlign: "center", color: "#fff" }}>
          Make Offer for this vehicle
        </h5>
      </CardHeader>
      <Form onSubmit={handleSubmit} id="car-form">
        <CardBody style={{ backgroundColor: "#eee" }}>
          {sent && (
            <div
              className="alert alert-info text-center"
              style={{ fontWeight: 400 }}
            >
              Thank you for your interest in this vehicle. We have received your
              offer. One of our representatives will contact you as soon as
              possible
            </div>
          )}
          <FormGroup row>
            <Label className="col-lg-3" htmlFor="offer" style={styles.labeltxt}>
              Offer:
            </Label>
            <Col lg="9">
              <InputGroup className="">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    {props.currency === "NGN" ? "₦" : "$"}
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="How much are you offering?"
                  id="offer"
                  type="number"
                  required
                  onChange={handleChange}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              className="col-lg-3"
              htmlFor="fullname"
              style={styles.labeltxt}
            >
              *Full Name:
            </Label>
            <Col lg="9">
              <Input
                placeholder="Enter your full Name"
                id="fullname"
                type="text"
                required
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg="6" className="mb-2">
              <Input
                placeholder="*Email Address"
                id="email"
                type="email"
                onChange={handleChange}
                required
              />
            </Col>
            <Col lg="6">
              <Input
                placeholder="Phone number"
                type="tel"
                onChange={handleChange}
                id="phone"
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Additional Comment"
              type="textarea"
              rows="3"
              id="comment"
              required
              onChange={handleChange}
              //   defaultValue={iniMsg}
            />
          </FormGroup>
          <FormGroup>
            <div className="text-center">
              <Button
                type="submit"
                className="btn-fill"
                style={{ backgroundColor: "#243655" }}
                disabled={loading}
                size="lg"
              >
                {loading ? "Sending.." : "Make Offer"}
              </Button>
            </div>
          </FormGroup>
        </CardBody>
      </Form>
    </Card>
  );
}

export default OfferForm;

const styles = {
  labeltxt: { fontWeight: 600 },
};
